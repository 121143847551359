export const strings = {
  sidebar: {
    preamble: (
      <span>
        If you need assistance, please email{' '}
        <a href="mailto:karlsonservice@apelson.co.uk">karlsonservice@apelson.co.uk</a>.
      </span>
    ),
    benefits: {
      title: 'Warranty Benefits',
      protection: <span>Additional 3 year Parts Warranty if product registered</span>,
      callCentre: <span>Dedicated Karlson Customer Service and Technical Support</span>,
      spareParts: (
        <span>
          If you need a spare part for your Karlson appliance, please email{' '}
          <a href="mailto:karlsonservice@apelson.co.uk">
            karlsonservice
            <wbr />
            @apelson.co.uk
          </a>
          .
        </span>
      ),
      engineers: <span>Nationwide engineer coverage</span>
    }
  },
  marketing: (
    <>
      <p>
        We would like to contact you from time to time about options available to you when your product reaches the end
        of its manufacturer's warranty. We can also provide information that can enhance the operation and longevity of
        your product.
      </p>
      <p>
        We will always keep your data safe and we will never share your details with anyone else. We will also never
        contact you by phone.
      </p>
    </>
  )
};

export const logo = {
  image: require('./karlson.png').default,
  width: 310,
  height: 52,
  alt: 'Karlson logo'
};

export const applyStyles = () => {
  if (document) {
    const style = document.createElement('style');
    document.head.appendChild(style);
    style.sheet.insertRule(`body, html {background-color:${backgroundColor}}`, 0);
  }
};

export const backgroundColor = '#fff';

export const endpoints = {
  prod: 'https://warreg.rpdns.co.uk/submit.php',
  dev: 'https://warreg.rpdns.co.uk/submit-dev.php'
};
