export const strings = {
  sidebar: {
    preamble: (
      <span>
        If you need assistance, please email <a href="mailto:cataservice@apelson.co.uk">cataservice@apelson.co.uk</a>.
      </span>
    ),
    benefits: {
      title: 'Warranty Benefits',
      protection: <span>Additional 3 year Parts Warranty if product registered</span>,
      callCentre: <span>Dedicated Cata Customer Service and Technical Support</span>,
      spareParts: (
        <span>
          Spare Parts available at{' '}
          <a href="https://cata-appliances.co.uk/" target="_blank">
            cata-appliances.co.uk
          </a>
        </span>
      ),
      engineers: <span>Nationwide engineer coverage</span>
    }
  },
  marketing: ''
};

export const logo = {
  image: require('./cata.png').default,
  width: 292,
  height: 88,
  alt: 'Cata logo'
};

export const applyStyles = () => {
  if (document) {
    const style = document.createElement('style');
    document.head.appendChild(style);
    style.sheet.insertRule('body, html {background-color:#fff;color:#333}', 0);
    style.sheet.insertRule('#app>header {margin-bottom:1rem}');
  }
};

export const endpoints = {
  prod: 'https://warreg.rpdns.co.uk/submit.php',
  dev: 'https://warreg.rpdns.co.uk/submit-dev.php'
};
